import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Zoom} from "@mui/material";
import {forwardRef, ReactElement, useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';

interface Props {
    initialScanResult: string;
    onClose: () => void;
    onSelectScanResult: (scanResult: string) => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});


export default function ScanResultDialog({initialScanResult, onClose, onSelectScanResult}: Props) {

    const [scanResult, setScanResult] = useState<string>(initialScanResult)

    useEffect(() => {
        setScanResult(initialScanResult)
    }, [initialScanResult])

    const editResult = () => {
        let newScanresult = prompt('Printer aanpassen', scanResult)
        if (newScanresult) {
            setScanResult(newScanresult)
        }
    }

    return <Dialog open={!!scanResult} onClose={onClose} fullWidth TransitionComponent={Transition}>
        <DialogTitle>
            <h3 style={{textAlign: 'center'}}>Bevestig printer</h3>
        </DialogTitle>
        <DialogContent dividers sx={{textAlign: 'center', position: 'relative'}}>
            <p>klopt het dat u de volgende printer gescand hebt?</p>
            <h2>{scanResult}</h2>

            <Button sx={{position: 'absolute', right: '8px', bottom: '8px', color: '#5bba4b'}} onClick={editResult}><EditIcon/></Button>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button onClick={() => onSelectScanResult(scanResult)}
                    style={{color: '#000', width: '100px', background: '#73e261'}} variant={'contained'}>Ja</Button>
            <Button onClick={onClose} variant={'outlined'} style={{width: '100px'}}>Nee</Button>
        </DialogActions>
    </Dialog>

}