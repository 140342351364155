
export default function Opera() {

    return <div>
        <h2>Installatie instructies Opera</h2>
        <p>Stap 1: Druk rechtsboven op de drie puntjes.</p>
        <p>Stap 2: Druk op de plus knop "Toevoegen aan..."</p>
        <p>Stap 3: Druk "Startscherm".</p>
        <p>Stap 4: Controleer de naam en druk op "Toevoegen".</p>
        <p>Stap 5: Druk onderin beeld nogmaals op "Toevoegen".</p>
        <p>De app is nu geïnstalleerd op uw telefoon, u kunt de huidige pagina sluiten.</p>
        <hr/>
        <a href="https://www.goedkoopprinten.nl/klantenservice">Hulp nodig? Wij helpen u graag verder.</a>

    </div>
}