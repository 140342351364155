
export default function Safari() {

    return <div>
        <h2>Installatie instructies Safari</h2>
        <p>Stap 1: Druk onderin op de "deel" knop (het vierkantje met een pijl omhoog).</p>
        <p>Stap 2: Kies “Zet op beginscherm”.</p>
        <p>Stap 3: Druk op de deelknop onderaan in het scherm.</p>
        <p>Stap 4: Bevestig de naam van de app door op "voeg toe" te klikken.</p>
        <p>De app is nu geïnstalleerd op uw telefoon, u kunt de huidige pagina sluiten.</p>
        <hr/>
        <a href="https://www.goedkoopprinten.nl/klantenservice">Hulp nodig? Wij helpen u graag verder.</a>
    </div>
}