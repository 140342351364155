
export default function Other() {

    return <div>
        <h2>Installatie instructies overige browsers</h2>

        <p>Stap 1: Druk op de drie puntjes rechtsboven, of rechtsonder in het scherm.</p>
        <p>Stap 2: Zoek de knop met "toevoegen aan startscherm", of "toevoegen aan".</p>
        <p>Stap 3: Druk op "Startscherm" of "Beginscherm".</p>
        <p>Stap 4: Controleer de naam en druk op "Toevoegen".</p>
        <p>De app is nu geïnstalleerd op uw telefoon, u kunt de huidige pagina sluiten.</p>

        <hr/>
        <a href="https://www.goedkoopprinten.nl/klantenservice">Hulp nodig? Wij helpen u graag verder.</a>

    </div>
}