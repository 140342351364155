import {useEffect, useState} from 'react';

type CameraFacingMode = "environment" | "user"

export const useCamera = () => {
    const [videoDem, handleVideoDem] = useState<{ w: number, h: number }>({w: 0, h: 0})
    const [cameraFacingMode, handleCameraFacingMode] = useState<CameraFacingMode>('environment')
    const [imageData, handleImageData] = useState('');
    let video: HTMLVideoElement;
    let canvas: HTMLCanvasElement;

    useEffect(() => {
        refreshCamera()
    }, [cameraFacingMode]);

    function refreshCamera() {
        try {
            //find video and canvas elements by tagNames
            video = document.getElementsByTagName('video')[0];
            canvas = document.getElementsByTagName('canvas')[0];
            let constraint = {
                video: {
                    width: {ideal: 1920},
                    height: {ideal: 1080},
                    facingMode: cameraFacingMode
                },
                audio: false
            }
            navigator.mediaDevices.getUserMedia(constraint).then((stream) => {
                if (!video || !canvas) return;
                video.setAttribute("playsinline", "true");
                video.srcObject = stream;
                video.onloadedmetadata = () => {
                    //get position of video tag;
                    let {clientLeft, clientTop, videoWidth, videoHeight} = video
                    handleVideoDem({w: videoWidth, h: videoHeight})
                    //align canvas position with video position
                    canvas.style.position = "absolute";
                    canvas.style.left = clientLeft.toString();
                    canvas.style.top = clientTop.toString();
                    canvas.setAttribute('width', videoWidth.toString());
                    canvas.setAttribute('height', videoHeight.toString());
                    video.play();
                }
            }).catch((e) => {
                console.log('Video error!', e);
            })
        } catch (e) {
            console.log('Video error!', e);
        }
    }

    const switchCameraFacingMode = () => {
        handleCameraFacingMode(old => (old === 'environment') ? "user" : "environment")
    }

    const captureImage = async (): Promise<HTMLCanvasElement | null> => {
        //take photo
        try {
            let video: HTMLVideoElement = document.getElementsByTagName('video')[0]
            let canvas: HTMLCanvasElement = document.getElementsByTagName('canvas')[0];
            let context = canvas.getContext('2d');
            context?.drawImage(video, 0, 0, videoDem.w, videoDem.h);
            let height = 100;
            let width = videoDem.w * 0.8;
            const widthOffset = videoDem.w * 0.1
            
            const margin = 100 / videoDem.h * window.innerHeight
            height = height / margin * 100

            const y = videoDem.h / 2 - (height / 2)
            const imageData = context?.getImageData(widthOffset, y, videoDem.w - widthOffset , height) ?? null;
            if (!imageData) return null;

            const scanCanvas = document.createElement('canvas')
            scanCanvas.width = width
            scanCanvas.height = height
            scanCanvas.getContext('2d')?.putImageData(imageData, 0, 0)
            return scanCanvas

        } catch (e) {
            console.log(e);
            return null
        }
    }

    return {cameraFacingMode, switchCameraFacingMode, imageData, captureImage, refreshCamera}
}