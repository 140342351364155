import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Zoom} from "@mui/material";
import React, {forwardRef, ReactElement, useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Chrome from "./help-pages/chrome";
import Samsung from "./help-pages/samsung";
import Opera from "./help-pages/opera";
import Safari from "./help-pages/safari";
import Other from "./help-pages/other";
import BrowserSelection from "./help-pages/browser-selection";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import style from './Help.module.css'

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});

export default function InstallDialog({open, onClose}: Props) {

    const [selected, setSelected] = useState<string>('initial')
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    useEffect(() => {
        if (!open) {
            setSelected('')
            setDetailsActive(false)
        }
    }, [open])

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // @ts-ignore
            deferredPrompt.prompt();
            // @ts-ignore
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    onClose()
                }
                setDeferredPrompt(null);
            });
        }
    };

    const initialContent = <div className={style.installPage}>
        <img src={'/logo_512.png'} alt={''}/>
        <Button className={style.installButton} onClick={handleInstallClick} variant={'contained'}>Installeer als
            app</Button>
        <Button onClick={() => setSelected('browser-selection')}>Knop werkt niet?</Button>
    </div>

    const [detailsActive, setDetailsActive] = useState<boolean>(false);

    const handleContentSwitch = (selected?: string | null) => {
        if (selected === 'browser-selection' || selected === '') {
            setSelected(selected);
            setDetailsActive(false);
        } else {
            // @ts-ignore
            setSelected(selected)
            setDetailsActive(true);
        }
    }

    const content =
        selected === 'chrome' ? <Chrome/> :
            selected === 'samsung' ? <Samsung/> :
                selected === 'opera' ? <Opera/> :
                    selected === 'safari' ? <Safari/> :
                        selected === 'other' ? <Other/> :
                            selected === 'browser-selection' ? <BrowserSelection onSelection={handleContentSwitch}/> :
                                initialContent;

    return <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
        <DialogTitle>
            {detailsActive ?
                <Button sx={{position: 'absolute', left: '8px', top: '8px'}}
                        color={'inherit'} onClick={() => handleContentSwitch('browser-selection')}><ArrowBackIcon/></Button>
                : null}
            <Button sx={{position: 'absolute', right: '8px', top: '8px'}} color={'inherit'}
                    onClick={onClose}><CloseIcon/></Button>
        </DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
    </Dialog>
}
