import {Button} from "@mui/material";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import React from "react";

interface Props {
    onSelection: (browser: string) => void;
}

export default function BrowserSelection({onSelection}: Props) {
    return <div>
        <p style={{textAlign: 'center'}}>Welke browser gebruikt u?</p>
        <Button className="browser" onClick={() => onSelection("chrome")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/chrome/chrome.png'} alt={'.'}/>
                <span>Chrome</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => onSelection("samsung")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/samsung/samsung.png'} alt={'.'}/>
                <span>Samsung internet</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => onSelection("opera")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/opera/opera.png'} alt={'.'}/>
                <span>Opera</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => onSelection("safari")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/safari/safari.png'} alt={'.'}/>
                <span>Safari</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => onSelection("other")}>
            <div className={"buttonContent"}>
                <PsychologyAltIcon sx={{position: "absolute", left: '8px', width: '50px', height: '80px'}}/>
                <span>Ik weet het niet</span>
            </div>
        </Button>
    </div>

}