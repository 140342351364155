
export default function Chrome() {

    return <div>
        <h2>Installatie instructies Google Chrome</h2>
        <p>Stap 1: Druk rechtsboven op de drie puntjes.</p>
        <p>Stap 2: Druk op "Toevoegen aan startscherm"</p>
        <p>Stap 3: Druk op "installeren"</p>
        <p>De app is nu geïnstalleerd op uw telefoon, u kunt de huidige pagina sluiten.</p>
        <hr/>

        <a href="https://www.goedkoopprinten.nl/klantenservice" style={{marginTop: '16px'}}>Hulp nodig? Wij helpen u
            graag verder.</a>

    </div>
}
