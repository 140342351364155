import {Button, Checkbox, Dialog, DialogActions, DialogContent, Fab, Slide, Zoom} from "@mui/material";
import {forwardRef, ReactElement, useEffect, useRef, useState} from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});


export default function HelpDialog({open, onClose}: Props) {

    const [dontShowAgain, setDontShowAgain] = useState(localStorage.getItem('showHelp') === 'false')

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            saveDontShowAgain();
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [dontShowAgain]); // Add any dependencies your saveDontShowAgain function relies on

    const saveDontShowAgain = () => {
        localStorage.setItem('showHelp', dontShowAgain ? 'false' : 'true')
    }

    const closeHandler = () => {
        onClose()
        saveDontShowAgain()
    }

    return <Dialog open={open} onClose={closeHandler} fullScreen
                   TransitionComponent={Transition}>
        <DialogContent sx={{position: 'relative'}}>
            <img src="logo_512.png"
                 style={{position: 'absolute', width: '50px', right: '8px', top: '16px', zIndex: 1}}/>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                zIndex: 2
            }}>
                <h2>Welkom</h2>
                <p>Deze applicatie helpt u bij het vinden van de juiste cartridges voor uw printer.</p>

                <p>
                    1. <br/> Vind het typenummer op uw printer. Vaak te vinden aan de voor- of bovenkant.<br/><br/>
                    2. <br/> Zorg dat het typenummer geheel binnen het groene kader valt. <br/><br/>
                    3. <br/> Druk op de groene camera knop: <Fab
                    style={{
                        background: '#73e261',
                        zIndex: 2,
                        margin: 'auto',
                        width: '40px',
                        height: '40px',
                    }}
                ><CameraAltIcon sx={{width: '20px'}}/></Fab> <br/><br/>
                    4. <br/> Controleer of de tekst overeenkomt met het typenummer van uw printer.
                </p>

            </div>
            <Button onClick={closeHandler} sx={{width: '100%', color: '#000', background: '#73e261'}}
                    variant={'contained'}>Start</Button>
        </DialogContent>
    </Dialog>

}