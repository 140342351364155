
export default function Samsung() {

    return <div>
        <h2>Installatie instructies Samsung Internet</h2>
        <p>Stap 1: Druk rechtsonder op de knop met drie lijntjes.</p>
        <p>Stap 2: Druk op het plus icoon met de tekst "Toevoegen aan".</p>
        <p>Stap 3: Druk op "Startscherm".</p>
        <p>Stap 4: Druk daarna op "Toevoegen".</p>
        <p>De app is nu geïnstalleerd op uw telefoon, u kunt de huidige pagina sluiten.</p>
        <hr/>
        <a href="https://www.goedkoopprinten.nl/klantenservice">Hulp nodig? Wij helpen u
            graag verder.</a>
    </div>
}